// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cinema-js": () => import("./../../../src/pages/cinema.js" /* webpackChunkName: "component---src-pages-cinema-js" */),
  "component---src-pages-contact-awards-js": () => import("./../../../src/pages/contact/awards.js" /* webpackChunkName: "component---src-pages-contact-awards-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-directors-js": () => import("./../../../src/pages/directors.js" /* webpackChunkName: "component---src-pages-directors-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-print-js": () => import("./../../../src/pages/print.js" /* webpackChunkName: "component---src-pages-print-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-cinema-detail-js": () => import("./../../../src/templates/cinema-detail.js" /* webpackChunkName: "component---src-templates-cinema-detail-js" */),
  "component---src-templates-print-detail-js": () => import("./../../../src/templates/print-detail.js" /* webpackChunkName: "component---src-templates-print-detail-js" */),
  "component---src-templates-work-detail-js": () => import("./../../../src/templates/work-detail.js" /* webpackChunkName: "component---src-templates-work-detail-js" */)
}

